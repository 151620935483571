<template>
  <b-container>
    <b-row class="match-height">
      <b-col>
        <!-- Datos Generales -->
        <b-card>
          <b-card-title>
            Datos Generales
          </b-card-title>
          <!-- form -->
          <validation-observer ref="formValidation">
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Nombre del Sorteo"
                    label-for="raffleName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre del Sorteo"
                      rules="required"
                    >
                      <b-form-input
                        id="raffleName"
                        v-model="raffle.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Nombre del Sorteo"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Categoría"
                    label-for="category"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="category"
                      rules="required"
                    >
                      <v-select
                        v-model="raffle.category_ids"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="name"
                        :options="categories"
                        :reduce="categories => categories.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group
                    label="Descripción"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="raffle.description"
                      placeholder=""
                      rows="4"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group
                    label="Fecha del Sorteo"
                    label-for="raffleName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Fecha del Sorteo"
                      rules="required"
                    >
                      <input
                        v-model="raffle.raffle_date"
                        type="datetime-local"
                        class="dateNtime"
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-group
                    label="Número de boletos"
                    label-for="raffleTickets"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Número de boletos"
                      rules="required"
                    >
                      <b-form-input
                        id="raffleTickets"
                        v-model="raffle.number_of_tickets"
                        type="number"
                        placeholder="100"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-group
                    label="Precio por boletos"
                    label-for="rafflePrice"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Precio por boletos"
                      rules="required"
                    >
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text>
                            <i class="fas fa-coins" />
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="raffle.price_of_ticket"
                          name="Precio"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Save button -->
                <b-col
                  class="d-flex justify-content-end mt-2"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="loading"
                    @click.prevent="onSaveForm"
                  >
                    <b-spinner
                      v-if="loading"
                      small
                    />
                    <span
                      v-if="loading"
                      class="ml-1"
                    >
                      Guardando sorteo
                    </span>
                    <span v-else>Guardar</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <div class="">
            <label
              for="stream"
              class="font-weight-bolder"
            >
              Link de LIVE Stream
            </label>
            <b-input-group prepend="https://www.youtube.com/embed/">
              <b-form-input
                v-model="raffle.url"
                name="stream"
              />
            </b-input-group>
          </div>

          <div class="mt-1">
            <label
              for="segob"
              class="font-weight-bolder"
            >
              Certificado de SEGOB
            </label>
            <file-base64
              :multiple="false"
              @change="setSegob($event)"
            />
            <!-- Show the file with a button to delte it -->
            <div
              v-if="$route.name === 'RaffleEditForm' && raffle.government_certificate !== null"
              class="box-file-uploaded my-2"
            >
              <div class="container text-primary">
                <div class="container-information">
                  <b-img
                    class="img-file"
                    :src="require(`@/assets/images/file_generic_gray.png`)"
                    alt="document"
                  />
                  <div class="information">
                    <p
                      class="name"
                      @click="openFile(raffle.government_certificate)"
                    >
                      Certificado de SEGOB
                    </p>
                  </div>
                </div>
                <b-button
                  style="font-size: 2rem; color: #000 !important; font-weight: 500;"
                  class="cursor-pointer btn-deleteFile"
                  @click="deleteFile()"
                >
                  <em class="fas fa-trash-alt mr-50" />
                </b-button>
              </div>
            </div>
          </div>

          <div class="mt-1">
            <label
              for="multimedia"
              class="font-weight-bolder"
            >
              Fotos y Videos
            </label>
            <base-cropper
              :model="raffle"
              :boundary-size="{ width: 1300, height: 740 }"
              :viewport-size="{ width: 1280, height: 720 }"
              @croppedImage="handleCropper($event)"
            />
            <section
              v-if="raffle.images.length && $route.name === 'CreateRaffle'"
            >
              <draggable
                :list="raffle.images"
                tag="ul"
                group="people"
                class="list-group list-group-flush cursor-move"
              >
                <div
                  v-for="(img, n) in raffle.images"
                  :key="`img-${n}`"
                  class="mt-2 preview-container"
                >
                  <b-button
                    variant="secondary"
                    pill
                    class="floating-btn btn-icon rounded-circle"
                    @click="deleteImage(n)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                  <b-img
                    :src="img.data"
                    width="150"
                  />
                </div>
              </draggable>
            </section>
            <section v-else>
              <div
                v-for="(img, n) in raffle.images"
                :key="`img-${n}`"
                class="mt-2 preview-container"
              >
                <b-button
                  variant="secondary"
                  pill
                  class="floating-btn btn-icon rounded-circle"
                  @click="deleteAndRemovedImage(img.id, n)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-img
                  :src="img.url"
                  width="150"
                />
              </div>
            </section>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import fileBase64 from '@core/components/vue-file-base64/src/js/components/vue-file-base64.vue'
import dayjs from 'dayjs'
import draggable from 'vuedraggable'
import BaseCropper from '@/@core/components/BaseCropper.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    fileBase64,
    BaseCropper,
    draggable,
  },
  data() {
    return {
      loading: false,
      file: null,
      raffle: {
        name: '',
        description: '',
        number_of_tickets: '',
        raffle_date: '',
        price_of_ticket: '',
        category_ids: [],
        government_certificate: {
          data: null,
          filename: null,
        },
        images: [

        ],
        url: '',
      },
      required,
    }
  },
  computed: {
    ...mapGetters('categories', ['categories']),
    ...mapGetters('raffles', ['currentRaffle']),
  },
  beforeMount() {
    this.getCategories({ by_active_status: true })
    if (this.$route.params.id) {
      this.getRaffle(this.$route.params.id)
        .then(response => {
          this.raffle = this.currentRaffle
          this.raffle.category_ids = this.currentRaffle.categories
          this.raffle.images = response.images
          this.raffle.raffle_date = dayjs(response.date).format('YYYY-MM-DDThh:mm')
        })
    }
  },
  methods: {
    ...mapActions('categories', ['getCategories']),
    ...mapActions('raffles', ['getRaffle', 'saveRaffle', 'editRaffle', 'publishRaffle', 'deleteRaffleImage']),
    setSegob(files) {
      this.raffle.government_certificate.data = files.base64
    },
    handleCropper(file) {
      this.raffle.images.push(file)
    },
    setImages(files) {
      this.raffle.images = files.map(({ base64 }) => ({
        data: base64,
      }))
    },
    deleteFile() {
      this.raffle.government_certificate = null
      this.raffle.government_certificate = {
        data: null,
        filename: null,
      }
    },
    openFile(url) {
      window.open(url, '_blank')
    },
    deleteImage(idx) {
      this.raffle.images.splice(idx, 1)
    },
    deleteAndRemovedImage(imgId, idx) {
      this.deleteRaffleImage({ id: this.$route.params.id, image_id: imgId })
      this.raffle.images.splice(idx, 1)
    },
    onSaveForm() {
      if (this.$route.params.id) {
        this.$refs.formValidation.validate().then(success => {
          if (success) {
            this.loading = true
            this.editRaffle(this.raffle)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sorteo actualizado',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({ name: 'RafflesList' })
              })
              .catch(error => {
                this.loading = false
                error.response.data.messages.forEach(err => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error',
                      message: err,
                      icon: 'InfoIcon',
                      variant: 'danger',
                    },
                  })
                })
              })
          }
        })
      } else {
        this.$refs.formValidation.validate().then(success => {
          if (success && this.raffle.images.length > 3) {
            this.loading = true
            this.raffle.government_certificate.filename = `certificate-${this.raffle.name}.pdf`
            this.saveRaffle(this.raffle)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sorteo guardado',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({ name: 'RafflesList' })
              })
              .catch(() => { this.loading = false })
          }
          if (this.raffle.images.length <= 3) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Se requiere como mínimo 3 imagenes para guardar el sorteo',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          }
          if (this.raffle.government_certificate.data === null) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Se requiere el certificado de SEGOB para guardar el sorteo',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.preview-container {
  position: relative;
}

.floating-btn {
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
}
.dateNtime{
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}
.box-file-uploaded {
  width: 100%;
  border: 2px solid #c0bfc4;
  position: relative;
  height: 70px;
  border-radius: 10px;
  margin-bottom: 0.5rem !important;
  .container {
    padding: 0.6rem;
    height: 100% !important;
    display: flex;
    justify-content: space-between !important;
    .container-information{
      display: flex;
      .img-file{
        margin-right: 0.5rem !important;
      }
      .information {
        margin-left: 0.4rem;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        p {
          margin: 0;
          padding: 0;
        }
        .name {
          color: #898991;
          font-weight: 700;
        }
        .type {
          color: #c5c5c7;
        }
      }
    }
    .btn-deleteFile{
      border: none !important;
      outline: none !important;
      background: none !important;
      .tache_arel_img{
        width: 20px !important;
      }
    }
  }
}
</style>
