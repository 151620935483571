<template>
  <div>
    <raffle-forms />
  </div>
</template>

<script>
import RaffleForms from '@/@core/components/raffles/RaffleForm.vue'

export default {
  components: {
    RaffleForms,
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>
